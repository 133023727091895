import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-1"},[(!_vm.isLoading && _vm.has)?_c('div',{staticClass:"d-flex flex-column session-details"},[_c('div',{style:(!_vm.VRMode
          ? 'flex: 1; overflow: auto; padding: 20px'
          : 'flex: 1; overflow: auto; padding: 8px 20px 20px 20px')},[(!_vm.VRMode)?_c('div',{staticClass:"px-4 mb-8"},[_c('div',{staticClass:"text-h4 font-weight-bold main--text d-flex mt-4"},[_c(VBadge,{attrs:{"content":_vm.$t('sessionsPage.new')}},[_vm._v(_vm._s(_vm.$t("sessionsPage.aiSessionTitle")))]),_c(VSpacer),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.aiFeedbackRelaxedQuota === 0 &&
              _vm.aiFeedbackFastQuota === 0 &&
              !_vm.existingAIFeedback},on:{"click":function () {
                !_vm.existingAIFeedback && _vm.getAIFeedback(_vm.id);
                _vm.isAIFeedbackOpen = true;
              }}},[(_vm.existingAIFeedback)?_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-check-circle")]):_vm._e(),_vm._v(_vm._s(_vm.$t("sessionsPage.aiFeedback")))],1)],1),_c('div',{staticClass:"text-center my-4"},[_c(VCard,{staticClass:"pa-6",staticStyle:{"width":"fit-content","text-align":"left"},attrs:{"elevation":"0"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"x-large":""}},[_vm._v("mdi-circle-multiple-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("quota.title")))]),_c(VCardText,{staticStyle:{"padding":"10px 10px 0 0"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_c(VIcon,{staticStyle:{"width":"40px"}},[_vm._v("mdi-chat-processing-outline")]),_vm._v(" "+_vm._s(_vm.$t("quota.fast"))+": "),_c('b',[_vm._v(_vm._s(_vm.aiFeedbackFast))]),_vm._v(", "+_vm._s(_vm.$t("quota.relaxed"))+": "),_c('b',[_vm._v(_vm._s(_vm.aiFeedbackRelaxed))])],1)]}}],null,false,3562530754)},[_c('span',[_vm._v(_vm._s(_vm.$t("quota.tip.aiFeedback")))])])],1),_c(VCardText,{staticStyle:{"padding":"10px 10px 0 0"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_c(VIcon,{staticStyle:{"width":"40px"}},[_vm._v("mdi-chart-bar")]),_vm._v(" "+_vm._s(_vm.$t("quota.fast"))+": "),_c('b',[_vm._v(_vm._s(_vm.analysisFast))]),_vm._v(", "+_vm._s(_vm.$t("quota.relaxed"))+": "),_c('b',[_vm._v(_vm._s(_vm.analysisRelaxed))])],1)]}}],null,false,2937988455)},[_c('span',[_vm._v(_vm._s(_vm.$t("quota.tip.analysis")))])])],1),_c(VCardActions,{staticClass:"mt-4 center w-full"},[(
                  _vm.analysisFast !== 'unlimited' ||
                  _vm.aiFeedbackFast !== 'unlimited'
                )?_c(VBtn,{attrs:{"width":"100%","to":"/subscription/pricing"}},[_vm._v(_vm._s(_vm.$t("quota.upgrade")))]):_vm._e()],1)],1)],1)]):_vm._e(),_c('SessionScoresCard',{attrs:{"enable-feedback":_vm.enableFeedback}}),_c('AudioAnalysisDetails'),(!_vm.VRMode)?_c('TextAnalysisDetails'):_vm._e()],1),_c('SessionControls')],1):_vm._e(),(_vm.isLoading)?_c('div',[_c(VOverlay,[_c('div',{staticClass:"d-flex flex-column align-center"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":""}}),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.yourSessionsSection.fetching"))+" ")])],1)])],1):_vm._e(),(!_vm.has)?_c('div',{staticClass:"d-flex flex-1"},[_c('div',{staticClass:"d-flex flex-1 justify-center align-center text-h6 pa-4"},[_vm._v(" "+_vm._s(_vm.$t("sess.noneselected"))+" ")])]):_vm._e(),_c(VDialog,{attrs:{"width":"1000","scrollable":""},model:{value:(_vm.isAIFeedbackOpen),callback:function ($$v) {_vm.isAIFeedbackOpen=$$v},expression:"isAIFeedbackOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-3 w-full align-end pb-4"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.aiFeedbackTitle"))+" "),_c(VSpacer),(_vm.existingAIFeedback)?_c(VCardSubtitle,{staticClass:"text-right py-0 caption grey--text"},[_vm._v(" "+_vm._s(_vm._f("formatwithtime")(_vm.existingAIFeedback.updatedAt))+" ")]):_vm._e()],1),(_vm.aiFeedbackLoading)?_c(VCardText,{staticClass:"d-flex flex-1",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"d-flex flex-1 justify-center align-center"},[_c(VIcon,{staticClass:"grey--text text--lighten-4 loading-spinner text-h3",staticStyle:{"position":"absolute"}},[_vm._v("mdi-circle-outline")]),_c(VIcon,{staticClass:"primary--text loading-spinner text-h3"},[_vm._v("mdi-loading")])],1)]):_c(VCardText,{staticClass:"py-8",staticStyle:{"white-space":"pre-wrap"}},[_c('vue-simple-markdown',{attrs:{"source":_vm.addTestEmojis(
              _vm.existingAIFeedback
                ? _vm.existingAIFeedback.feedbackText
                : _vm.aiFeedbackMessage
            )}})],1),_c(VDivider),_c(VCardActions,[(_vm.existingAIFeedback)?_c(VCardSubtitle,{staticClass:"text-right py-0 px-3 caption grey--text mt-4"},[_vm._v(" Model: v"+_vm._s(_vm.existingAIFeedback.modelVersion)+" ")]):_vm._e(),_c(VSpacer),_c(VBtn,{staticClass:"mt-4",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function () {
              _vm.isAIFeedbackOpen = false;
            }}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }